export class Calculation {
    id!: number;
    userId!: string;
    name!: string;
    description!: string;
    notes!: string;
    homePrice: number = 250000;
    downPaymentAmount: number = 0;
    downPaymentPercentage: number = 0;
    term: number = 30;
    interest: number = 4.25;
    creditEstimate: string = '720+';
    homeInsurance!: number;
    propertyTaxes!: number;
    mortgageInsurance!: number;
    hoa!: number;
    monthlyPayment!: number;
    version!: number;
    // dateCreated!: Date;
    // dateModified!: Date;
    createdOn!: Date;
    modifiedOn!: Date;
    loanType!: any;
    nmls!: string;
    recipientEmail?: string;
}

export class CalculationShare extends Calculation {
    recipientEmail!: string;
}