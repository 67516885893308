export interface IQueryParams{
    filter?: string,
    take?: number,
    skip?: number,
    count?: boolean,
    order?: string,
    includes?: string,
}

export interface ISelectParams{
    id: number,
    urlSuffix?: string,
    includes?: string,
}

export interface IUpdateParams {
    objId: number,
    uniqueId?: number,
    rowVersion?: string,
}


export const getQueryParams = (params: IQueryParams): string => {
    let url = `?`;
    if (params.order){
        url +=  `&$orderBy=${params.order}`
    }
    if(params.skip){
        url += `&$skip=${params.skip}`;
    }
    if(params.take){
        url += `&$top=${params.take}`;
    }
    if(params.count){
        url += '&$count=true'
    }    
    if(params.includes){
        url += `&$expand=${params.includes}`;
    }
    if (params.filter) {
        url += `&$filter=${params.filter}`;
    }
    return url;
}

export const getSelectParams = (params: ISelectParams): string => `/${params.id}${params.urlSuffix ? params.urlSuffix : ''}${params.includes ? `?includes=${params.includes}` : ''}`;


export  const getUpdateParams = (params: IUpdateParams, putIdInUrl = true): string => {
    let url = "";
    if (params.rowVersion) {
        url = `/${putIdInUrl ? (params.uniqueId ? params.uniqueId : params.objId) : ''}?rv=${params.rowVersion.split('+').join('%2B')}`;
    } else {
        url = `/${putIdInUrl ? (params.uniqueId ? params.uniqueId : params.objId) : ''}`;
    }
    return url;
}