import { Subject } from 'rxjs';

import { Calculation } from '../types/calculation';
import INotification, { NotificationType } from '../types/notification';
import NotificationService from './notification-service';

export enum CalculationTypes {
    CONVENTIONAL = 1,
    FHA = 2,
    VA = 3,
    USDA = 4
}

interface ICalculationTypesExtended {
    type: CalculationTypes;
    name: string;
}

class MIPOptions {
    creditEstimate: string = '';
    term!: number[];
    rangeMin!: number;
    rangeMax!: number;
    range: string = '';
    value!: number;
}

export class CalculationOptions {
    upfrontMIP: number | MIPOptions[] = 0;
    MIP: number | MIPOptions[] = 0;
}

const CalcOptions_v1: { calcType: CalculationTypes, options: CalculationOptions }[] = [
    {
        calcType: CalculationTypes.CONVENTIONAL, options: {
            upfrontMIP: 0,
            MIP: [
                { creditEstimate: '600-639', term: [30, 20, 15], rangeMin: 0, rangeMax: 79.99, range: '0-80', value: 0 },

                { creditEstimate: '600-639', term: [30], rangeMin: 80, rangeMax: 84.99, range: '80-85', value: 0.44 },
                { creditEstimate: '600-639', term: [30], rangeMin: 85, rangeMax: 89.99, range: '85-90', value: 0.94 },
                { creditEstimate: '600-639', term: [30], rangeMin: 90, rangeMax: 94.99, range: '90-95', value: 1.42 },
                { creditEstimate: '600-639', term: [30], rangeMin: 95, rangeMax: 100, range: '95-100', value: 1.86 },

                { creditEstimate: '600-639', term: [20, 15], rangeMin: 80, rangeMax: 84.99, range: '80-85', value: 0.28 },
                { creditEstimate: '600-639', term: [20, 15], rangeMin: 85, rangeMax: 89.99, range: '85-90', value: 0.56 },
                { creditEstimate: '600-639', term: [20, 15], rangeMin: 90, rangeMax: 94.99, range: '90-95', value: 1.14 },
                { creditEstimate: '600-639', term: [20, 15], rangeMin: 95, rangeMax: 100, range: '95-100', value: 1.72 },

                { creditEstimate: '640-679', term: [30, 20, 15], rangeMin: 0, rangeMax: 79.99, range: '0-80', value: 0 },

                { creditEstimate: '640-679', term: [30], rangeMin: 80, rangeMax: 84.99, range: '80-85', value: 0.38 },
                { creditEstimate: '640-679', term: [30], rangeMin: 85, rangeMax: 89.99, range: '85-90', value: 0.9 },
                { creditEstimate: '640-679', term: [30], rangeMin: 90, rangeMax: 94.99, range: '90-95', value: 1.28 },
                { creditEstimate: '640-679', term: [30], rangeMin: 95, rangeMax: 100, range: '95-100', value: 1.54 },

                { creditEstimate: '640-679', term: [20, 15], rangeMin: 80, rangeMax: 84.99, range: '80-85', value: 0.23 },
                { creditEstimate: '640-679', term: [20, 15], rangeMin: 85, rangeMax: 89.99, range: '85-90', value: 0.42 },
                { creditEstimate: '640-679', term: [20, 15], rangeMin: 90, rangeMax: 94.99, range: '90-95', value: 0.83 },
                { creditEstimate: '640-679', term: [20, 15], rangeMin: 95, rangeMax: 100, range: '95-100', value: 1.34 },

                { creditEstimate: '680-719', term: [30, 20, 15], rangeMin: 0, rangeMax: 79.99, range: '0-80', value: 0 },

                { creditEstimate: '680-719', term: [30], rangeMin: 80, rangeMax: 84.99, range: '80-85', value: 0.28 },
                { creditEstimate: '680-719', term: [30], rangeMin: 85, rangeMax: 89.99, range: '85-90', value: 0.65 },
                { creditEstimate: '680-719', term: [30], rangeMin: 90, rangeMax: 94.99, range: '90-95', value: 0.96 },
                { creditEstimate: '680-719', term: [30], rangeMin: 95, rangeMax: 100, range: '95-100', value: 1.21 },

                { creditEstimate: '680-719', term: [20, 15], rangeMin: 80, rangeMax: 84.99, range: '80-85', value: 0.21 },
                { creditEstimate: '680-719', term: [20, 15], rangeMin: 85, rangeMax: 89.99, range: '85-90', value: 0.33 },
                { creditEstimate: '680-719', term: [20, 15], rangeMin: 90, rangeMax: 94.99, range: '90-95', value: 0.67 },
                { creditEstimate: '680-719', term: [20, 15], rangeMin: 95, rangeMax: 100, range: '95-100', value: 1.01 },

                { creditEstimate: '720+', term: [30, 20, 15], rangeMin: 0, rangeMax: 79.99, range: '0-80', value: 0 },

                { creditEstimate: '720+', term: [30], rangeMin: 80, rangeMax: 84.99, range: '80-85', value: 0.23 },
                { creditEstimate: '720+', term: [30], rangeMin: 85, rangeMax: 89.99, range: '85-90', value: 0.46 },
                { creditEstimate: '720+', term: [30], rangeMin: 90, rangeMax: 94.99, range: '90-95', value: 0.66 },
                { creditEstimate: '720+', term: [30], rangeMin: 95, rangeMax: 100, range: '95-100', value: 0.87 },

                { creditEstimate: '720+', term: [20, 15], rangeMin: 80, rangeMax: 84.99, range: '80-85', value: 0.16 },
                { creditEstimate: '720+', term: [20, 15], rangeMin: 85, rangeMax: 89.99, range: '85-90', value: 0.21 },
                { creditEstimate: '720+', term: [20, 15], rangeMin: 90, rangeMax: 94.99, range: '90-95', value: 0.39 },
                { creditEstimate: '720+', term: [20, 15], rangeMin: 95, rangeMax: 100, range: '95-100', value: 0.53 }
            ]
        }
    },
    {
        calcType: CalculationTypes.FHA, options: {
            upfrontMIP: 1.75,
            MIP: [
                { creditEstimate: '', term: [30, 20], rangeMin: 0, rangeMax: 95, range: '0-95', value: 0.8 },
                { creditEstimate: '', term: [30, 20], rangeMin: 95.01, rangeMax: 100, range: '95-100', value: 0.85 },
                { creditEstimate: '', term: [15], rangeMin: 0, rangeMax: 90, range: '0-90', value: 0.45 },
                { creditEstimate: '', term: [15], rangeMin: 90.01, rangeMax: 100, range: '90-100', value: 0.7 }
            ]
        }
    },
    {
        calcType: CalculationTypes.USDA, options: {
            upfrontMIP: 1,
            MIP: 0.35
        }
    },
    {
        calcType: CalculationTypes.VA, options: {
            upfrontMIP: [
                { creditEstimate: '', term: [30, 20, 15], rangeMin: 0, rangeMax: 90, range: '0-90', value: 1.25 },
                { creditEstimate: '', term: [30, 20, 15], rangeMin: 90.01, rangeMax: 95, range: '90-95', value: 1.5 },
                { creditEstimate: '', term: [30, 20, 15], rangeMin: 95.01, rangeMax: 100, range: '95-100', value: 2.15 }],
            MIP: 0
        }
    }
]

export const CalculationTypesExtended: ICalculationTypesExtended[] = [
    { type: CalculationTypes.CONVENTIONAL, name: "Conventional" },
    { type: CalculationTypes.FHA, name: "FHA" },
    { type: CalculationTypes.VA, name: "VA" },
    { type: CalculationTypes.USDA, name: "USDA" }
]

export default class CalculationService {
    private notification: INotification = NotificationService.getInstance();
    calcTypeChangedEvent: Subject<CalculationTypes>;
    currentType: ICalculationTypesExtended;

    private editCalculation!: Calculation;

    private static instance: CalculationService;

    constructor() {
        this.calcTypeChangedEvent = new Subject<CalculationTypes>();
        //Set by default current type = Conventional
        this.currentType = CalculationTypesExtended[0];
    }

    static getInstance() {
        if (!CalculationService.instance) {
            CalculationService.instance = new CalculationService();
        }
        return CalculationService.instance;
    }

    changeCalcType(type: CalculationTypes) {
        this.setCurrentType(type);
        this.calcTypeChangedEvent.next(type);
    }

    setCurrentType(type: CalculationTypes | string) {
        //ToDo: remove this switch when existing types will be converted
        switch (type) {
            case "CONVENTIONAL":
            case null:
                type = CalculationTypes.CONVENTIONAL
                break;
            case "FHA":
                type = CalculationTypes.FHA
                break;
            case "USDA":
                type = CalculationTypes.USDA
                break;
            case "VA":
                type = CalculationTypes.VA
                break;
            default:
                break;
        }
        this.currentType = CalculationTypesExtended.filter(x => x.type == type)[0];
    }

    getCalcOptions(loanPercent: number, term: number, creditEstimates: string = ''): CalculationOptions {
        let typeOptions = CalcOptions_v1.filter(x => x.calcType === this.currentType.type)[0].options as CalculationOptions;
        let options: CalculationOptions = new CalculationOptions();
        options.MIP = Array.isArray(typeOptions.MIP)
            ? this.getMipValue(typeOptions.MIP as MIPOptions[], loanPercent, term, creditEstimates)
            : typeOptions.MIP;
        options.upfrontMIP = Array.isArray(typeOptions.upfrontMIP)
            ? this.getMipValue(typeOptions.upfrontMIP as MIPOptions[], loanPercent, term, '')
            : typeOptions.upfrontMIP;
        return options;
    }

    getMipValue(mipOptions: MIPOptions[], loanPercent: number, term: number, creditEstimates: string = '') {
        let option = mipOptions.filter(x => x.term.some(t => t == term) && loanPercent <= x.rangeMax && loanPercent >= x.rangeMin && x.creditEstimate === creditEstimates);
        if (option.length > 0) {
            return option[0].value;
        }
        this.notification.show(NotificationType.warning, "There is no MIP value for this case. Current MIP = 0.", 'Warning', 10000);
        return 0;
    }

    // edit calculations___________
    setEditCalculation = (calculation: Calculation) => { this.setCurrentType(calculation.loanType); this.editCalculation = calculation };
    getEditCalculation = () => this.editCalculation;
    clearEditCalculation = () => this.editCalculation = new Calculation();
    // ____________edit calculations
}