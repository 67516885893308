import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import ModalBaseComponent, { IModalBaseProps, IModalBaseState } from "../base/modal-base-component";
import { CrossBottomSVG } from "../../assets/icons/svg-icons";
import ReactDOM from "react-dom";

interface IMortgageTermOptionsProps extends IModalBaseProps {
    onChange: (event: any) => void;
    onSave: () => void;
    name: string;
    description: string;
}

interface IMortgageTermOptionsState extends IModalBaseState {
}

export default class EditModal extends ModalBaseComponent<IMortgageTermOptionsProps, IMortgageTermOptionsState>{
    nameInput: any;

    constructor(props: IMortgageTermOptionsProps) {
        super(props);
        this.nameInput = ReactDOM.findDOMNode(this.refs.nameInput);
    }

    componentDidMount() {
        
        setTimeout(() => {
            if (this.nameInput)
                this.nameInput.focus();
        }, 100)
    }

    onSave = (event: any) => {
        event.preventDefault();
        this.props.onSave();
    }

    render() {
        return (
            <Modal isOpen={this.state.showModal} backdrop={true} toggle={() => this.toggle()} fade={this.state.modalFade} contentClassName={"bg-dark"} centered={true} onClosed={() => this.props.onClose()}>
                <form onSubmit={this.onSave}>
                    <ModalHeader className="text-center" toggle={() => this.toggle()} charCode="">
                        Before you save your loan please provide a property name and short description.
                    <button type="button" className="close" aria-label="Close" onClick={() => this.toggle()}>
                            <CrossBottomSVG />
                        </button>
                    </ModalHeader>
                    <ModalBody >
                        <div className="form-group">
                            <label className="text-secondary" htmlFor="name">Property Name:</label>
                            <input
                                ref={(e: any) => this.nameInput = e}
                                required
                                className="form-control text-left"
                                id="_name"
                                name="name"
                                autoFocus={true}
                                autoComplete="off"
                                value={this.props.name || ''}
                                onChange={(event: any) => this.props.onChange(event)}
                            />
                        </div>
                        <div className="form-group">
                            <label className="text-secondary" htmlFor="description">Short Description:</label>
                            <textarea
                                className="form-control text-left"
                                id="_description"
                                name="description"
                                autoComplete="off"
                                value={this.props.description || ''}
                                onChange={(event: any) => this.props.onChange(event)}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter className="pt-0 justify-content-center">
                        <button type="submit" className="btn btn-primary text-white text-uppercase w-100" data-toggle="modal" data-target=".save-modal" > Confirm</button>
                    </ModalFooter>
                </form>
            </Modal>
        );
    }
}
