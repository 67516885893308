import React from "react";
import BaseComponent, { IProps, IState } from "./base/base-component";
import CalculationItem from "./calculation-item";
import { Calculation } from "../types/calculation";
import EmailFormComponent from "./email/email-form";
import { CalculatorService } from "../services/calculator-service";
import UserDataService from "../services/user-data-service";
import CalculationService from "../services/calculation-service";
import ConfirmComponent from "./confirmation";

// const calculations: Calculation[] = [
//     {
//         id: 1,
//         userId: 'roman@consensus.com',
//         name: '198 Marlboro Street',
//         description: 'Lorem ipsum dolor sit amet, consectetur lorem adipiscing elit, sed do eiusmod tempor',
//         notes: 'bla-bla-bla',
//         homePrice: 300000,
//         downPaymentAmount: 60000,
//         downPaymentPercentage: 12,
//         term: 30,
//         interest: 3.75,
//         creditEstimate: 'creditEstimate',
//         homeInsurance: 980,
//         propertyTaxes: 980,
//         mortgageInsurance: 980,
//         hoa: 980,
//         monthlyPayment: 3213,
//         version: 1,
//         dateCreated: new Date(),
//         dateModified: new Date(),
//     },

// ]


interface ICalculationListState extends IState {
    calculations: Calculation[];
    selectedCalculation: Calculation;
    showShareModal: boolean;
}
interface ICalculationListProps extends IProps {
    history: any;
}

export default class CalculationsListComponent extends BaseComponent<ICalculationListProps, ICalculationListState>{

    private dataSvc: CalculatorService<Calculation> = new CalculatorService();
    private userDataSvc: UserDataService = UserDataService.getInstance();
    private _calcService: CalculationService = CalculationService.getInstance();

    constructor(props: ICalculationListProps) {
        super(props);

        this.state = {
            calculations: [],
            selectedCalculation: new Calculation,
            showShareModal: false,
        }
    }

    componentDidMount() {
        this.getLoanData(this.userDataSvc.getUserData().id);
    }

    getLoanData = (email: string): void => {
        if (email) {
            this.showLoading(true)
            this.dataSvc.getLoan(email)
                .then((res: any) => {
                    this.setState({ calculations: res })
                    this.showLoading(false);
                })
                .catch(err => this.handleError(err))
        }
    }

    editCalculation = (calculation: Calculation): void => {
        this._calcService.setEditCalculation(calculation);
        this.props.history.push('/calculator')
    }
    shareCalculation = (calculation: Calculation): void => {
        this.setState({ selectedCalculation: calculation }, () => {
            this.setState({ showShareModal: true });
        });
    }

    applyCalculation = (id: number): void => {
        this.notifyInfo('apply')
    }

    removeCalculation = (id: number): void => {
        const index = this.state.calculations.findIndex((x: Calculation) => x.id == id);
        if (index >= 0) {
            this.showLoading(true);
            this.dataSvc.deleteLoan(this.state.calculations[index].id)
                .then(() => {
                    this.setState((prevState: ICalculationListState) => {
                        prevState.calculations.splice(index, 1);
                        return { calculations: prevState.calculations }
                    }, () => {
                        this.notifyInfo('removed');
                        this.showLoading(false);
                    });
                })
                .catch(err => this.handleError(err))
        }
    }

    deleteLoan = (item: Calculation) => { }



    render() {
        return (
            <main>
                <div className="container">
                    <ConfirmComponent
                        onDelete={(f: any) => this.deleteLoan = f}
                        onConfirm={(item: Calculation) => this.removeCalculation(item.id)}
                    />
                    {
                        this.state.calculations.map((calculation: Calculation, i: number) =>
                            <React.Fragment key={i}>
                                <CalculationItem
                                    calculation={calculation}
                                    onClick={(calculation: Calculation) => this.editCalculation(calculation)}
                                    onShare={(calculation: Calculation) => this.shareCalculation(calculation)}
                                    onApply={(id: number) => this.applyCalculation(id)}
                                    onRemove={() => this.deleteLoan(calculation)}
                                />
                            </React.Fragment>
                        )
                    }

                </div>
                <EmailFormComponent
                    calculation={this.state.selectedCalculation}
                    showShareModal={this.state.showShareModal}
                    onClose={() => { this.setState({ showShareModal: false }) }}
                />
            </main>
        )
    }
}

