import ResourceDataService from "./resource-data-service";
import { getUpdateParams } from "../../types/url-params";
import { BaseEntity } from "../../types/general";




export default class DataService<T extends BaseEntity> extends ResourceDataService<T> {

    constructor(url: string) {
        super(url);
    }

    async create(obj: T, method: string = 'POST'): Promise<T> {
        const res = await super.requestJSON(this.url, method, obj);
        return res;
    }

    async update(obj: T, uniqueId: number = 0, putIdInUrl = true): Promise<T> {
        const res = await super.requestJSON(this.url + getUpdateParams({ objId: obj.id, uniqueId: uniqueId }, putIdInUrl), 'PUT', obj);
        return res;
    }

    async delete(id: number, putIdInUrl = true): Promise<null> {
        return await super.requestJSON(`${this.url}/${putIdInUrl ? id : ''}`, 'DELETE', putIdInUrl ? '' : { id: id });
    }

    async deleteItem(item: any): Promise<null> {
        return await super.requestJSON(`${this.url}`, 'DELETE', { id: item.id, rowVersion: item.rowVersion });

    }
    async deleteArr(arrIds: { id: number }[]): Promise<null> {
        return await super.requestJSON(this.url, 'DELETE', arrIds);
    }
}