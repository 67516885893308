import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import BaseComponent, { IState, IProps } from '../base/base-component';
import ValueName from '../../types/general';
import CalculationTypesComponent from '../calculator/calculator-types';
import { CogSVG, BackArrowSVG, ArrowBottomGreenSVG } from '../../assets/icons/svg-icons';
import CalculationService from '../../services/calculation-service';

interface IMenuProps extends IProps {
}

interface IMenuState extends IState {
    loading: boolean,
    showCalcTypesModal: boolean;
}

const routerTitles: ValueName[] = [
    { value: "/", name: "Welcome" },
    { value: "/calculator", name: "Loan Type" },
    { value: "/calculations", name: "Your Loans" }
]

class TopMenuComponent extends BaseComponent<IMenuProps & RouteComponentProps<{}>, IMenuState> {
    private _calcService: CalculationService = CalculationService.getInstance();

    constructor(props: IMenuProps & RouteComponentProps<{}>) {
        super(props);

        this.state = {
            loading: false,
            showCalcTypesModal: false
        };
    }

    getTitle() {
        let routerTitleItem = routerTitles.filter(x => x.value == this.props.location.pathname);
        let title = routerTitleItem.length > 0 ? routerTitleItem[0].name : "Title";
        // return title == 'Loan Type' ? `${title} -  ${this._calcService.currentType.name}` : title;
        if (title == 'Loan Type') {
            return (
                <div onClick={() => this.setState({ showCalcTypesModal: true })}>
                    {`${title} - ${this._calcService.currentType.name}`}
                    <span className="arrow-in-title" >
                        <ArrowBottomGreenSVG />
                    </span>
                </div>
            )
        } else {
            return title;
        }
    }

    render() {
        return (
            <nav className="nav bg-dark navbar-dark fixed-top nav--top py-2">
                <div className="container">
                    <ul className="navbar-nav flex-row justify-content-between align-items-center w-100">
                        <li className="nav-item">
                            <a className="nav-link text-white" onClick={() => this.props.history.goBack()}>
                                <BackArrowSVG></BackArrowSVG>
                            </a>
                        </li>
                        <li className="nav-item">
                            <h5 className="mb-0 text-white">{this.getTitle()}</h5>
                        </li>

                        <li className="nav-item">
                            {/* {this.props.location.pathname == '/calculator' ?
                            <a className="nav-link text-white" onClick={() => this.setState({ showCalcTypesModal: true })}>
                               <ArrowBottomSVG />
                            </a> : null} */}
                        </li>
                    </ul>
                </div>
                <CalculationTypesComponent showModal={this.state.showCalcTypesModal} onClose={() => this.setState({ showCalcTypesModal: false })}></CalculationTypesComponent>
            </nav>
        );
    }
}

export default withRouter(TopMenuComponent);