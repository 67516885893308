import React from "react";
import BaseComponent, { IProps, IState } from "./base/base-component";
import { ShareSVG, ApplySVG, RemoveSVG } from "../assets/icons/svg-icons";
import { Calculation } from "../types/calculation";
interface ICalculationState extends IState {

}
interface ICalculationProps extends IProps {
    calculation: Calculation;
    onShare: (calculation: Calculation) => void;
    onApply: (id: number) => void;
    onRemove: () => void;
    onClick: (calculation: Calculation) => void;

}


export default class CalculationItem extends BaseComponent<ICalculationProps, ICalculationState>{

    render() {
        const { calculation } = this.props;
        return (
            <section className="card bg-dark" >
                <div className="card-body">
                    <div onClick={() => this.props.onClick(calculation)}>
                        <h5 className="card-title text-white d-flex justify-content-between align-items-center">
                            <span>{calculation.name}</span>
                        </h5>
                        <h6 className="card-subtitle text-primary mb-2">
                            ${parseFloat(`${calculation.homePrice}`).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        </h6>
                        <p className="card-text text-secondary">
                            {calculation.description}
                        </p>
                        <div className="divider"></div>
                        <div className="row">
                            <div className="col-7">
                                <p className="mb-1 text-white d-flex flex-wrap justify-content-between">
                                    Estimated Payment: <span className="font-weight-bold text-primary pr-2">${calculation.monthlyPayment}</span>
                                </p>
                                <p className="mb-0 text-white d-flex flex-wrap justify-content-between">
                                    {calculation.term} Years Fixed
                                    </p>
                            </div>
                            <div className="col-5 pl-1">
                                <p className="mb-1 text-white d-flex flex-wrap justify-content-between">
                                    <span>Down Payment:</span><span>{calculation.downPaymentPercentage}%</span>
                                </p>
                                <p className="mb-0 text-white d-flex flex-wrap justify-content-between">
                                    <span>Interest Rate:</span><span>{calculation.interest}%</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="card-actions d-flex flex-row justify-content-between">
                        <button className="btn text-white d-flex align-items-center text-decoration-none" onClick={() => this.props.onShare(calculation)}>
                            <ShareSVG />
                            <span>Share</span>
                        </button>
                        <button className="btn text-white d-flex align-items-center text-decoration-none" onClick={() => this.props.onApply(calculation.id)}>
                            <ApplySVG />
                            <span>Apply</span>
                        </button>
                        <button className="btn text-white d-flex align-items-center text-decoration-none" onClick={() => this.props.onRemove()}>
                            <RemoveSVG />
                            <span>Remove</span>
                        </button>
                    </div>
                </div>
            </section>
        )
    }
}