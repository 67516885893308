import * as React from 'react';
import loadImg  from '../assets/images/loader.gif';

export const LoadIndicator = (show: boolean) => {
    document.body.style.overflowY = `${show ? 'hidden' : 'auto'}`;
    return (
        <div className={`loader ${show? 'show' : ''}`}>
            <img src={loadImg} alt="loader" />
        </div>
    )
}