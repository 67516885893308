import createNumberMask from 'text-mask-addons/dist/createNumberMask'
export class MaskConfig {
    allowDecimal: boolean = true;
    prefix: string = '';
    suffix: string = '';


    public constructor(init?:Partial<MaskConfig>) {
        Object.assign(this, init);
    }
}
export const UnmaskItems: string[] = ['\\$', '%', ',']

export const NumberDollarMaskConfig: MaskConfig = new MaskConfig({prefix:'$'})
export const NumberPercentMaskConfig: MaskConfig = new MaskConfig({suffix:'%'})

export const NumberDollarMask = createNumberMask(NumberDollarMaskConfig)
export const NumberPercentMask = createNumberMask(NumberPercentMaskConfig);