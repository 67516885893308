import DataService from "./data/data.service";
import { BaseEntity } from "../types/general";

export class CalculatorService<T extends BaseEntity> extends DataService<T> {
    constructor() {
        super('')
    }

    getLoan = (email: string) => super.requestJSON(`${this.url}getcalc/${email}`, 'GET');

    createLoan = (obj: T) => super.requestJSON(`${this.url}logcalc`, 'POST', obj, null, true);

    shareLoan = (obj: T) => super.requestJSON(`${this.url}logcalcshare`, 'POST', obj, null, true);

    updateLoan = (obj: T) => super.requestJSON(`${this.url}updatecalc/${obj.id}`, 'POST', obj, null, true);

    deleteLoan = (id: number) => super.requestJSON(`${this.url}removecalc/${id}`, 'GET', null, null, true);

}