import BaseComponent, { IProps, IState } from '../base/base-component';

export interface IModalBaseProps extends IProps {
    showModal: boolean;
    onClose: () => void;
}

export interface IModalBaseState extends IState {
    showModal: boolean;
    modalFade: boolean;
}

export default class ModalBaseComponent<P extends IModalBaseProps, S extends IModalBaseState> extends BaseComponent<P, S> {
    constructor(props: P) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState(): S {
        return {
            showModal: this.props.showModal,
            modalFade: true
        } as S;
    }

    componentDidUpdate(prevProps: IModalBaseProps): void {
        if (this.props.showModal == true && this.props.showModal !== prevProps.showModal)
            this.toggle();
    }

    toggle() {
        this.setState({
            showModal: !this.state.showModal,
            modalFade: !this.state.modalFade
        });
    }
}