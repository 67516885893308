import React from 'react';
import iphone from '../assets/images/iphone.png';
import { Link } from 'react-router-dom';
import BaseComponent, { IState, IProps } from './base/base-component';


import queryString, { ParsedQuery } from 'query-string';
import UserDataService, { UserData } from '../services/user-data-service';

interface IWelcomeState extends IState {
  showCalculationTypes: boolean;
}
interface IWelcomeProps extends IProps {
  location: any
}

interface QueryParams {
  id: string;
}
export default class WelcomeComponent extends BaseComponent<IWelcomeProps, IWelcomeState> {
  private queryParams: ParsedQuery<string> = queryString.parse(this.props.location.search);
  private userDataSvc: UserDataService = UserDataService.getInstance();

  constructor(props: IWelcomeProps) {
    super(props);

    this.state = {
      showCalculationTypes: false,
    }

  }

  componentDidMount() {
    if (this.queryParams) {
      this.saveUserData(this.queryParams);
    }
  }

  saveUserData = (queryParams: ParsedQuery<string>): void => {
    const userData: UserData = {
      id: queryParams.id as string,
      nmls: queryParams.nmls as string
    }
    this.userDataSvc.setUserData(userData);
  }

  render() {


    return (
      <main className="welcome" >
        <div className="container">
          <div className="content">
            <section className="body">
              <img className="welcome-img d-block mx-auto" src={iphone} alt="Iphone" />
            </section>
            <section className="card bg-dark">
              <div className="card-body">
                <div className="card-title text-center text-white">
                  <h5>Mortgage Calculator</h5>
                </div>
                <div className="card-text text-center text-white">
                  <p>Use our mortgage calculator to estimate your monthly mortgage payment including principal and interest, taxes, insurance, PMI and more. Please choose purchase or refinance option below.</p>
                </div>
                <div className="card-actions d-flex justify-content-around">
                  <Link className="btn btn-outline-primary text-white text-uppercase" to="/calculator">Purchase</Link>
                  <Link className="btn btn-outline-primary text-white text-uppercase" to="/calculator">Refinance</Link>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* <CalculationTypesComponent showShareModal={this.state.showCalculationTypes} onClose={() => this.setState({ showCalculationTypes: false })}></CalculationTypesComponent> */}
      </main>
    );
  }
}
