import INotification, { NotificationType } from "../types/notification";
import { store } from 'react-notifications-component';


export default class NotificationService implements INotification {

    private static instance: NotificationService;

    static getInstance() {
        if (!NotificationService.instance) {
            NotificationService.instance = new NotificationService();
        }
        return NotificationService.instance;
    }

    show(type: NotificationType, message: string, title: string = '', duration: number = 5000) {
        store.addNotification({
            title,
            message,
            type,
            insert: "top",
            container: "bottom-right",
            animationIn: ["animated", "flipInY"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration,
                onScreen: true,
                pauseOnHover: true
            }
        })

    }

}