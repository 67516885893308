import React from "react";
import INotification, { NotificationType } from "../../types/notification";
import NotificationService from "../../services/notification-service";
import LoadIndicatorService, { ILoadIndicator } from "../../services/loadindicator-service";
import { ErrorResponse, ErrorDetails } from "../../types/error";

export interface IState {
}

export interface IProps {
   
}

export default class BaseComponent<P extends IProps, S extends IState> extends React.Component<P, S>{
    protected loadIndicator: ILoadIndicator = LoadIndicatorService.getInstance();
    private notification: INotification = NotificationService.getInstance();

    constructor(props: P) {
        super(props);
    }

    protected showLoading = (show: boolean): any => this.loadIndicator.notify(show);

    notifyError = (msg: string): void => this.notification.show(NotificationType.danger , msg, 'Error', 5000);
    notifyWarning = (msg: string): void => this.notification.show(NotificationType.warning, msg, 'Warning', 10000); 
    notifyInfo = (msg: string): void => this.notification.show(NotificationType.info, msg, 'Info', 3000);
    notifySuccess = (msg: string = " "): void => this.notification.show(NotificationType.success, msg, 'Success', 2000);
    notifyDefault = (msg: string): void => this.notification.show(NotificationType.default, msg, '', 1000);


    handleError = (err: Error | ErrorResponse): void => {
        this.showLoading(false);
        if (err instanceof ErrorResponse) {

            if ((err as ErrorResponse).details) {
                if ((err as ErrorResponse).details.status === 401) {
                    this.notifyError(`Unauthorized. Please sign in.`);
                    return;
                }
                if ((err as ErrorResponse).details.status === 404) {
                    this.notifyError(`Data not found.`);
                    return;
                }
                if ((err as ErrorResponse).details.status === 403) {
                    this.notifyError(`Forbidden.`);
                    return;
                }
                (err as ErrorResponse).details.json()
                    .then((data: ErrorDetails | any) => {

                        if (this.onError(data)) {
                            return;
                        }

                        if (data.detail) {
                            this.notifyError(data.detail);
                        }
                    })
                    .catch(err => {
                        this.notifyError(`Server error occured. Please contact system administrator.`);
                    });
            } else {
                this.notifyError(`Failed to process request. Please contact system administrator.`);
            }
        }
        else {
            this.notifyError(`Failed to process request. Please contact system administrator.`);
        }
    }

    onError(e: ErrorDetails): boolean {
        return false;
    }

  
}