import React from 'react';

import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import './App.scss';
import 'react-notifications-component/dist/theme.css';
import ReactNotification from 'react-notifications-component';
import WelcomeComponent from './components/welcome';
import CalculatorComponent from './components/calculator/calculator';
import TopMenuComponent from './components/navigation/top-menu';
import BottomMenuComponent from './components/navigation/bottom-menu';
import CalculationsListComponent from './components/calculations-list';
import BaseComponent, { IProps, IState } from './components/base/base-component';
import { LoadIndicator } from './components/loadindicator';

interface IAppState extends IState {
  loading: boolean;
}

interface IAppProps extends IProps {
}

export default class App extends BaseComponent<IAppProps, IAppState> {

  constructor(props: IAppProps) {
    super(props);

    this.state = {
      loading: false,
    }
  }

  componentDidMount() {

    this.loadIndicator.subscribe((visible: boolean) => this.setState({ loading: visible }));
  }

  render() {
    return (
      <div>
        <Router>
          <TopMenuComponent />
          <Switch>
            <Route exact path="/" component={WelcomeComponent} />
            <Route exact path="/calculator" component={CalculatorComponent} />
            <Route exact path="/calculations" component={CalculationsListComponent} />
            <Redirect to="/" />
          </Switch>
          <BottomMenuComponent />
        </Router>        
        <ReactNotification />
        {LoadIndicator(this.state.loading)}
      </div>
    )
  }
}
