import React from 'react';
import BaseComponent, { IProps, IState } from '../base/base-component';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import CalculationService, { CalculationTypesExtended, CalculationTypes } from '../../services/calculation-service';
import { CrossBottomSVG } from '../../assets/icons/svg-icons';

interface ICalculationTypesProps extends IProps {
    showModal: boolean;
    onClose: () => void;
}

interface ICalculationTypesState extends IState {
    showModal: boolean;
    modalFade: boolean;
}

export default class CalculationTypesComponent extends BaseComponent<ICalculationTypesProps, ICalculationTypesState> {
    private _calcService: CalculationService = CalculationService.getInstance();

    constructor(props: ICalculationTypesProps) {
        super(props);

        this.state = {
            showModal: false,
            modalFade: true
        };
    }

    componentDidUpdate(prevProps: ICalculationTypesProps): void {
        if (this.props.showModal === true && this.props.showModal !== prevProps.showModal)
            this.toggle();
    }

    toggle() {
        this.setState({
            showModal: !this.state.showModal,
            modalFade: !this.state.modalFade
        });
    }

    changeType(type: CalculationTypes) {
        this._calcService.changeCalcType(type);
        this.toggle();
    }

    render() {
        return (
            <Modal isOpen={this.state.showModal} backdrop={true} toggle={() => this.toggle()} fade={this.state.modalFade} contentClassName={"bg-dark"} centered={true} onClosed={() => this.props.onClose()}>
                <ModalHeader className="text-center" toggle={() => this.toggle()} charCode="">
                    <button type="button" className="close" aria-label="Close" onClick={() => this.toggle()}>
                        <CrossBottomSVG></CrossBottomSVG>
                    </button>
                    Please choose your loan type
                </ModalHeader>
                <ModalBody className="pb-3">
                    {CalculationTypesExtended.map((value, index) =>
                        <div className="row" key={index}>
                            <div className="col-12">
                                <a className={`btn-option text-white d-flex justify-content-center text-uppercase mb-2 ${(value.type === this._calcService.currentType.type ? 'btn-option--active' : '')}`} onClick={() => this.changeType(value.type)}>{value.name}</a>
                            </div>
                        </div>)}
                </ModalBody>
                {/* <ModalFooter className="pt-0 justify-content-center">
                    <Button type="button" color="primary" className="btn text-uppercase w-100">Confirm</Button>
                </ModalFooter> */}
            </Modal>
        );
    }
}

