
import BaseComponent, { IProps, IState } from "../../base/base-component";
import React from "react";
import "./advanced-options.scss"
import { Popover, PopoverBody } from "reactstrap";
import MaskedInput from 'react-text-mask'
import { NumberDollarMask } from "../../../shared/masks";
import CalculationService, { CalculationTypes } from "../../../services/calculation-service";
import { QuestionMarkSVG, ArrowBottomGreenSVG } from "../../../assets/icons/svg-icons";

interface IAdvancedOptionsProps extends IProps {
    homeInsurance: number;
    propertyTaxes: number;
    mortgageInsurance: number;
    hoa: number;
    showAdvancedOptions: boolean;
    close: () => void;
    onChange: (event: any) => void;
}

interface IAdvancedOptionsState extends IState {
    mortgageInsurance: number | null;
}

export default class AdvancedOptionsComponent extends BaseComponent<IAdvancedOptionsProps, IAdvancedOptionsState>{
    private _calcService: CalculationService = CalculationService.getInstance();

    constructor(props: IAdvancedOptionsProps) {
        super(props);
        this.state = {
            mortgageInsurance: null
        }
    }

    componentDidUpdate(prevProps: IAdvancedOptionsProps): void {
        if (this.props.mortgageInsurance !== prevProps.mortgageInsurance)
            this.setState({ 'mortgageInsurance': this.props.mortgageInsurance })
    }

    render() {
        return (
            <Popover placement="top" isOpen={this.props.showAdvancedOptions} target="calculator-popover-btn" className="calculator-popover custom-popover" hideArrow={true}>
                <a tabIndex={0} className="d-flex justify-content-center" id="calculator-popover-btn" role="button" onClick={() => this.props.close()}>
                    <div className="text-green">
                        Advanced<span className="arrow-in-title" ><ArrowBottomGreenSVG /></span>
                    </div>
                </a>
                <PopoverBody>

                    <form>
                        <div className="form-row">
                            <div className="form-group col-6">
                                <label className="text-white d-flex align-items-center" htmlFor="#">
                                    <QuestionMarkSVG></QuestionMarkSVG>
                                    <span className="d-block pl-1">Home Insurance:</span>
                                </label>
                                <MaskedInput
                                    className="form-control text-right"
                                    id="home-insurance"
                                    name="homeInsurance"
                                    inputMode={'decimal'}
                                    autoComplete="off"
                                    placeholder="$0"
                                    mask={NumberDollarMask}
                                    value={this.props.homeInsurance || ''}
                                    onChange={this.props.onChange}
                                />
                            </div>
                            <div className="form-group col-6">
                                <label className="text-white d-flex align-items-center" htmlFor="#">
                                    <QuestionMarkSVG />
                                    <span className="d-block pl-1">Property Taxes:</span>
                                </label>
                                <MaskedInput inputMode={'decimal'} autoComplete="off" mask={NumberDollarMask} className="form-control text-right" id="property-taxes" placeholder="$0" name="propertyTaxes" value={this.props.propertyTaxes || ''} onChange={this.props.onChange} />
                            </div>
                            {this._calcService.currentType.type !== CalculationTypes.VA ?
                                <div className="form-group col-6">
                                    <label className="text-white d-flex align-items-center" htmlFor="#">
                                        <QuestionMarkSVG />
                                        <span className="d-block pl-1">Mortgage Insurance:</span>
                                    </label>
                                    <MaskedInput inputMode={'decimal'} autoComplete="off" mask={NumberDollarMask} className="form-control text-right" id="mortgage-insurance" placeholder="$0" name="mortgageInsurance" value={this.props.mortgageInsurance || ''} onChange={this.props.onChange} />
                                </div> : null}
                            <div className="form-group col-6">
                                <label className="text-white d-flex align-items-center" htmlFor="#">
                                    <QuestionMarkSVG />
                                    <span className="d-block pl-1">HOA:</span>
                                </label>
                                <MaskedInput inputMode={'decimal'} autoComplete="off" mask={NumberDollarMask} className="form-control text-right" id="hoa" placeholder="$0" name="hoa" value={this.props.hoa || ''} onChange={this.props.onChange} />
                            </div>
                        </div>
                    </form>
                </PopoverBody>
            </Popover>
        );
    }
}