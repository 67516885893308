import React from 'react';
import BaseComponent, { IProps, IState } from '../base/base-component';
import EmailForm from '../../types/email-form';
import { Calculation } from '../../types/calculation';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import DataService from '../../services/data/data.service';
import { CrossBottomSVG } from '../../assets/icons/svg-icons';
import { CalculatorService } from '../../services/calculator-service';

const sgMail = require('@sendgrid/mail');

interface IEmailFormProps extends IProps {
    calculation: Calculation;
    showShareModal: boolean;
    onClose: () => void;
}

interface IEmailFormState extends IState {
    emailForm: EmailForm;
    showModal: boolean;
    modalFade: boolean;
}

export default class EmailFormComponent extends BaseComponent<IEmailFormProps, IEmailFormState> {
    mailSvc: DataService<any> = new DataService('');

    private dataSvc: CalculatorService<Calculation> = new CalculatorService();

    constructor(props: IEmailFormProps) {
        super(props);

        this.state = {
            emailForm: new EmailForm(),
            showModal: this.props.showShareModal,
            modalFade: true
        };

        this.handleEmailFormChange = this.handleEmailFormChange.bind(this);

        const SENDGRID_API_KEY = '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';
        sgMail.setApiKey(SENDGRID_API_KEY);
    }

    handleEmailFormChange(event: any) {
        console.dir(this.state);
        this.setState({
            emailForm: {
                ...this.state.emailForm,
                [event.target.name]: event.target.value
            }
        })
    }

    componentDidUpdate(prevProps: IEmailFormProps): void {
        if (this.props.showShareModal === true && this.props.showShareModal !== prevProps.showShareModal)
            this.toggle();
    }

    toggle() {
        this.setState({
            showModal: !this.state.showModal,
            modalFade: !this.state.modalFade
        });
    }

    //https://github.com/ereztdev/react-form-sendgrid/blob/master/src/componenets/ComponentForm.js
    sendEmail = (e: any) => {
        e.preventDefault();
        // let emailForm = { ...this.state.emailForm };
        // const msg = {
        //     crossDomain: true,
        //     to: emailForm.recipient,
        //     from: emailForm.sender,
        //     subject: "Calculation",
        //     text: "HI Dima",
        // }

        //sgMail.send(msg).then((msg:any) => {console.dir(msg)});
        // sgMail.send(msg);

        const _calculation = this.props.calculation;
        _calculation.recipientEmail = this.state.emailForm.recipient;
        _calculation.notes = this.state.emailForm.text;

        this.dataSvc.shareLoan(_calculation)
            .then(() => {
                this.setState({ showModal: false });
                this.notifySuccess('This loan was shared');
            })
            .catch((err) => {
                this.setState({ showModal: false });
                this.handleError(err);
            })

    }

    render() {
        return (
            <Modal isOpen={this.state.showModal} backdrop={true} toggle={() => this.toggle()} fade={this.state.modalFade} contentClassName={"bg-dark"} centered={true} onClosed={() => this.props.onClose()}>
                <ModalHeader className="text-center" toggle={() => this.toggle()} charCode="">
                    <button type="button" className="close" aria-label="Close" onClick={() => this.toggle()}>
                        <CrossBottomSVG />
                    </button>
                    Share your loan information please enter an email address and a short note.
                </ModalHeader>
                <form onSubmit={this.sendEmail}>
                    <ModalBody className="pb-0">
                        <div className="form-group">
                            <label className="text-white" htmlFor="email">Email Address:</label>
                            <input
                                required
                                type="email"
                                id="email"
                                className="form-control"
                                name="recipient"
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                value={this.state.emailForm.recipient || ''}
                                onChange={this.handleEmailFormChange}
                            />
                        </div>
                        <div className="form-group">
                            <label className="text-white" htmlFor="note">Property Notes:</label>
                            <textarea className="form-control" id="note" rows={3} name="text" value={this.state.emailForm.text || ''} onChange={this.handleEmailFormChange}></textarea>
                        </div>
                    </ModalBody>
                    <ModalFooter className="pt-0 justify-content-center">
                        <Button type="submit" color="primary" className="btn text-uppercase w-100">Send Email</Button>
                    </ModalFooter>
                </form>
            </Modal>
        );
    }
}
