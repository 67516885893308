import BaseDataService from "./base-data-service";
import { IQueryParams, getQueryParams, ISelectParams, getSelectParams } from "../../types/url-params";
import { BaseEntity } from "../../types/general";


export interface IResourceServiceConfig  {
}

export interface IQueryResult<T> {
    data: T[];
    total: number;
}

export default class ResourceDataService<T extends BaseEntity> extends BaseDataService {

    constructor(url:  string) {
        super(url);
    }

    query(params: IQueryParams): Promise<T[]> {

        const promise = super.requestJSON(this.url + getQueryParams(params))
            .then(res => {
                const _res = res.data ? res.data : res
                return _res;
            });
        return promise;
    }

    select(params: ISelectParams): Promise<T> {
        return super.requestJSON(this.url + getSelectParams(params))
            .then(res => {
                // return res.data ? res.data : res;
                return res;
            })
    }

}