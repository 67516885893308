import React from 'react';
import BaseComponent, { IProps, IState } from '../base/base-component';
import { Calculation } from '../../types/calculation';
import { CalculationOptions } from '../../services/calculation-service';
import MortgageChart from './mortgage-chart/mortgage-chart';
import AdvancedOptionsComponent from './advanced-options/advanced-options';
import EmailFormComponent from '../email/email-form';
import MaskedInput from 'react-text-mask'
import { NumberPercentMask, NumberDollarMask, UnmaskItems } from '../../shared/masks';
import MortgageTermOptionsComponent from './mortgage-term-options/mortgage-term-options';

import CalculationService, { CalculationTypes } from '../../services/calculation-service';
import { CalendarSVG, ArrowBottomGreenSVG, ArrowUpGreenSVG } from '../../assets/icons/svg-icons';
import { CalculatorService } from '../../services/calculator-service';
import UserDataService from '../../services/user-data-service';
import EditModal from './edit-modal';

interface ICalculatorProps extends IProps {
}

interface ICalculatorState extends IState {
    calculation: Calculation;
    showAdvancedOptions: boolean;
    showShareModal: boolean;
    showEditModal: boolean;
    showOptionModal: boolean;
    totalLoan: number;
}

export default class CalculatorComponent extends BaseComponent<ICalculatorProps, ICalculatorState>{
    private _calcService: CalculationService = CalculationService.getInstance();
    private dataSvc: CalculatorService<Calculation> = new CalculatorService();
    private userDataSvc: UserDataService = UserDataService.getInstance();

    creditEstimatesLookup: string[] = [];
    calculationOptions: CalculationOptions = new CalculationOptions();
    lastDownPaymentFieldChanged: string = '';
    currentCalculation: Calculation = new Calculation();
    originName: string = '';
    originDescription: string = '';
    // totalLoan: number = 0;

    constructor(props: ICalculatorProps) {
        super(props);
        this.state = {
            calculation: this.setDefaultCalcValues(),
            showAdvancedOptions: false,
            showShareModal: false,
            showEditModal: false,
            showOptionModal: false,
            totalLoan: 0
        };

        this.handleCalculatorChange = this.handleCalculatorChange.bind(this);
        this.setLookupData();
        this._calcService.calcTypeChangedEvent.subscribe((type: CalculationTypes) => {
            this.changeType(type)
        });
        setTimeout(() => {
            this.generalCalc();
        }, 100);
    }

    setDefaultCalcValues(): Calculation {
        let calculation = new Calculation;
        calculation.homePrice = 250000;
        calculation.interest = 4.25;
        calculation.term = 30;
        calculation.creditEstimate = "720+";
        switch (this._calcService.currentType.type) {
            case CalculationTypes.CONVENTIONAL:
                //Change both value to avoid run calculation dependent fields on default values set.
                calculation.downPaymentPercentage = 20;
                calculation.downPaymentAmount = 50000;
                break;
            case CalculationTypes.FHA:
                calculation.downPaymentPercentage = 3.5;
                calculation.downPaymentAmount = 8750;
                break;
            default:
                calculation.downPaymentPercentage = 0;
                calculation.downPaymentAmount = 0;
                break;
        }
        return calculation;
    }

    componentWillUnmount() {
        if (this.state.calculation && this.state.calculation.id) {
            this._calcService.clearEditCalculation();
        }
    }

    changeType = (type: CalculationTypes) => {
        this.setState({
            calculation: {
                ...this.state.calculation,
                loanType: type,
            }
        }, () => this.generalCalc());
    }

    componentDidMount() {
        this.setCalculationData(this._calcService.getEditCalculation());
    }

    setCalculationData = (calculation: Calculation): void => {
        if (calculation && calculation.id) {
            calculation.homePrice = +calculation.homePrice
            calculation.downPaymentAmount = +calculation.downPaymentAmount;
            calculation.downPaymentPercentage = +calculation.downPaymentPercentage;
            calculation.interest = +calculation.interest;

            calculation.homeInsurance = +calculation.homeInsurance;
            calculation.propertyTaxes = +calculation.propertyTaxes;
            calculation.hoa = +calculation.hoa;
            calculation.mortgageInsurance = +calculation.mortgageInsurance;

            calculation.loanType = calculation.loanType ? calculation.loanType : CalculationTypes.CONVENTIONAL;
            calculation.term = (+calculation.term == 15 || +calculation.term == 20 || +calculation.term == 30) ? +calculation.term : 15;
            // calculation.creditEstimate = "640-679";
            this.setState({ calculation }, () => this._calcService.changeCalcType(calculation.loanType))
        } else {
            this.setDefaultData();
        }
    }

    setDefaultData = (): void => {
        const _userData = this.userDataSvc.getUserData();
        if (_userData) {
            this.setState({
                calculation: {
                    ...this.state.calculation,
                    userId: _userData.id,
                    nmls: _userData.nmls,
                    loanType: this.state.calculation.loanType ? this.state.calculation.loanType : CalculationTypes.CONVENTIONAL
                }
            })
        }
    }

    saveCalculation = (): void => {
        this.showLoading(true);
        if (this.state.calculation.id) {
            this.dataSvc.updateLoan(this.state.calculation)
                .then(() => {
                    this.setState({ showEditModal: false });
                    this.notifySuccess('Data was updated');
                    this.showLoading(false);
                })
                .catch(err => this.handleError(err));
        } else {
            const _calculation = Object.assign({}, this.state.calculation);
            _calculation.userId = this.userDataSvc.getUserData().id;
            if (_calculation.userId) {
                this.dataSvc.createLoan(_calculation)
                    .then(() => {
                        this.setState({ showEditModal: false });
                        this.notifySuccess('Data was created');
                        this.showLoading(false);
                    })
                    .catch(err => this.handleError(err))
            } else {
                this.setState({ showEditModal: false });
                this.notifyWarning('Can not find user email. Please contact system administrator.');
            }
        }
    }

    onSave = (event: any): void => {
        event.preventDefault();
        this.originName = this.state.calculation.name
        this.originDescription = this.state.calculation.description;
        this.setState({ showEditModal: true })
    }

    cancelEditing = (): void => {
        this.setState({
            calculation: {
                ...this.state.calculation,
                description: this.originDescription,
                name: this.originName,
            }
        }, () => {
            this.originName = '';
            this.originDescription = '';
        })
        this.setState({ showEditModal: false })
    }

    setLookupData() {
        this.creditEstimatesLookup = ['600-639', '640-679', '680-719', '720+']
    }

    handleCalculatorChange(event: any, unmask: boolean = false) {
        let value = event.target.value;
        let name = event.target.name;
        if (unmask) {
            UnmaskItems.forEach(x => { let re = new RegExp(x, 'g'); value = value.replace(re, '') });
            value = value.trim();
        }
        this.setState({
            calculation: {
                ...this.state.calculation,
                [name]: this.validationFields(name, value)
            }
        }, () => { this.calcDependentFields(name) });

    }

    validationFields = (fieldName: string, value: any): number => {
        switch (fieldName) {
            case 'downPaymentAmount': {
                if (value > (this.state.calculation.homePrice / 100 * 90)) {
                    this.notifyInfo('Down Payment can not be more than 90% from Home price')
                    return this.state.calculation.homePrice / 100 * 90;
                } else {
                    return +value;
                }
            };
            case 'downPaymentPercentage': {
                if (value > 90) {
                    this.notifyInfo('Down Payment can not be more than 90%')
                    return 90;
                } else {
                    return value;
                }
            };
            default: return value;
        }
    }

    setCalculationState(name: string, value: number) {
        this.setState({
            calculation: {
                ...this.state.calculation,
                [name]: this.validationFields(name, value)
            }
        }, () => {
            if ((this.state.calculation.homePrice / 100 * 90) < this.state.calculation.downPaymentAmount) {
                this.setState({
                    calculation: {
                        ...this.state.calculation,
                        ['downPaymentAmount']: this.state.calculation.homePrice / 100 * this.state.calculation.downPaymentPercentage
                    }
                })
            }
        })
    }

    calcDependentFields(fieldName: string) {
        if (fieldName == 'downPaymentAmount' || fieldName == 'downPaymentPercentage') {
            this.lastDownPaymentFieldChanged = fieldName;
        }
        if (this.state.calculation.homePrice) {
            switch (fieldName) {
                case 'homePrice':
                    if (this.state.calculation.downPaymentAmount && this.lastDownPaymentFieldChanged == 'downPaymentAmount') {
                        this.setCalculationState('downPaymentPercentage', Math.floor(this.state.calculation.downPaymentAmount / this.state.calculation.homePrice * 10000) / 100);
                        // } else if (this.state.calculation.downPaymentPercentage && this.lastDownPaymentFieldChanged == 'downPaymentPercentage') {
                    } else if (this.state.calculation.downPaymentPercentage) {
                        this.setCalculationState('downPaymentAmount', Math.floor(this.state.calculation.homePrice * this.state.calculation.downPaymentPercentage / 100));
                    }
                    break;
                case 'downPaymentAmount':
                    this.setCalculationState('downPaymentPercentage', Math.floor(this.state.calculation.downPaymentAmount / this.state.calculation.homePrice * 10000) / 100);
                    break;
                case 'downPaymentPercentage':
                    this.setCalculationState('downPaymentAmount', Math.floor(this.state.calculation.homePrice * this.state.calculation.downPaymentPercentage / 100));
                    break;
                default:
                    break;
            }
        }
        //todo: better place to call        
        setTimeout(() => {
            this.generalCalc();
        }, 100);
    }

    generalCalc() {
        if ((this.state.calculation.homePrice && this.state.calculation.homePrice != 0) && this.state.calculation.term && this.state.calculation.interest) {
            if (this._calcService.currentType.type === CalculationTypes.CONVENTIONAL && !this.state.calculation.creditEstimate)
                return;
            let calcOptions = new CalculationOptions();
            let baseLoanAmount = this.state.calculation.homePrice - this.state.calculation.downPaymentAmount;
            if (baseLoanAmount > 0) {
                calcOptions = this._calcService.getCalcOptions(Math.ceil(baseLoanAmount / this.state.calculation.homePrice * 10000) / 100, this.state.calculation.term as number, this._calcService.currentType.type == CalculationTypes.CONVENTIONAL ? this.state.calculation.creditEstimate : '');
            }
            else {
                //this.notifyWarning("Home Price is lesser than Down Payment. It is not a valid case for a mortgage calculator. Please change it.");
            }
            let upfrontMipAmount = baseLoanAmount * (calcOptions.upfrontMIP as number) / 100;
            //todo: do we need to store it to state?
            let mortgageInsurance = baseLoanAmount * (calcOptions.MIP as number) / 12 / 100;
            let totalLoan = baseLoanAmount + upfrontMipAmount;
            let rateCoef = this.state.calculation.interest / 12 / 100;
            let loanTherm = this.state.calculation.term * 12;
            let monthlyPayment = Math.floor(totalLoan * rateCoef * Math.pow(1 + rateCoef, loanTherm) / (Math.pow(1 + rateCoef, loanTherm) - 1));
            let totalMonthlyPayment = monthlyPayment + this.state.calculation.mortgageInsurance + this.state.calculation.propertyTaxes + this.state.calculation.homeInsurance + this.state.calculation.hoa;
            this.setState({
                calculation: {
                    ...this.state.calculation,
                    monthlyPayment: monthlyPayment,
                    mortgageInsurance: mortgageInsurance,
                },
                totalLoan
            });
        }
    }


    render() {

        return (
            <main>
                <div className="container">
                    <MortgageChart
                        // calculateTotal={true}
                        calculation={this.state.calculation}
                        totalLoan={this.state.totalLoan}
                    />
                    <section className="card bg-dark">
                        <div className="card-body">
                            <form onSubmit={this.onSave}>
                                <div className="form-group">
                                    <label className="text-secondary" htmlFor="home-price">Home Price:</label>
                                    <MaskedInput
                                        required
                                        className="form-control text-right"
                                        id="home-price"
                                        name="homePrice"
                                        inputMode={'decimal'}
                                        placeholder="$0"
                                        autoComplete="off"
                                        mask={NumberDollarMask}
                                        value={this.state.calculation.homePrice || ''}
                                        onChange={(event: any) => this.handleCalculatorChange(event, true)}
                                    />
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-12 mb-0">
                                        <label className="text-secondary" htmlFor="down-payment">Down Payment:</label>
                                    </div>
                                    <div className="form-group col-7">
                                        <MaskedInput
                                            className="form-control text-right"
                                            id="down-payment"
                                            name="downPaymentAmount"
                                            inputMode={'decimal'}
                                            autoComplete="off"
                                            placeholder="$0"
                                            mask={NumberDollarMask}
                                            value={this.state.calculation.downPaymentAmount || ''}
                                            onChange={(event: any) => this.handleCalculatorChange(event, true)}

                                        />
                                    </div>
                                    <div className="form-group col-5">
                                        <MaskedInput
                                            className="form-control text-right"
                                            name="downPaymentPercentage"
                                            inputMode={'decimal'}
                                            placeholder="0%"
                                            autoComplete="off"
                                            mask={NumberPercentMask}
                                            value={this.state.calculation.downPaymentPercentage || ''}
                                            onChange={(event: any) => this.handleCalculatorChange(event, true)}
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-7">
                                        <label className="text-secondary" htmlFor="mortgage-term">Mortgage Term:</label>
                                        <button className="form-control d-flex align-items-center justify-content-between" type="button" onClick={() => this.setState({ showOptionModal: true })}>
                                            <CalendarSVG />
                                            <span className="term-value">{this.state.calculation.term} Year Fixed</span>
                                            <ArrowBottomGreenSVG />
                                        </button>
                                    </div>
                                    <div className="form-group col-5">
                                        <label className="text-secondary" htmlFor="mortgage-term">Interest Rate:</label>
                                        <MaskedInput
                                            className="form-control text-right"
                                            name="interest"
                                            inputMode={'decimal'}
                                            placeholder="0%"
                                            autoComplete="off"
                                            mask={NumberPercentMask}
                                            value={this.state.calculation.interest || ''}
                                            onChange={(event: any) => this.handleCalculatorChange(event, true)}
                                        />
                                    </div>
                                </div>
                                {this._calcService.currentType.type === CalculationTypes.CONVENTIONAL ?
                                    <div className="form-row">
                                        <div className="col-12">
                                            <label className="text-secondary" htmlFor="#">Estimate your credit:</label>
                                        </div>
                                        <div className="col-12 d-flex flex-row flex-wrap justify-content-between">
                                            {this.creditEstimatesLookup.map((value, index) =>
                                                <div className="custom-control custom-radio" key={index}>
                                                    <input type="radio" id={"radio-" + index} name="creditEstimate" className="custom-control-input" value={value} onChange={this.handleCalculatorChange} checked={this.state.calculation.creditEstimate == value} />
                                                    <label className="custom-control-label text-white" htmlFor={"radio-" + index}>{value}</label>
                                                </div>
                                            )}
                                        </div>
                                    </div> : null}
                                    <a tabIndex={0} style={{ paddingTop: '1rem' }} className="d-flex justify-content-center" id="calculator-popover-btn" role="button" onClick={() => this.setState({ showAdvancedOptions: true })}>
                                    <div className="text-green">
                                        Advanced<span className="arrow-in-title" >
                                            <ArrowBottomGreenSVG />
                                        </span>
                                    </div>
                                </a>
                                <AdvancedOptionsComponent
                                    homeInsurance={this.state.calculation.homeInsurance}
                                    propertyTaxes={this.state.calculation.propertyTaxes}
                                    mortgageInsurance={this.state.calculation.mortgageInsurance}
                                    hoa={this.state.calculation.hoa}
                                    showAdvancedOptions={this.state.showAdvancedOptions}
                                    close={() => this.setState({ showAdvancedOptions: false })}
                                    onChange={(event: any) => this.handleCalculatorChange(event, true)}
                                />
                                <div className="form-row mt-4 mb-4">
                                    <div className="col-3">
                                        <a className="btn btn-outline-primary text-white text-uppercase" onClick={() => this.setState({ showShareModal: true })}>
                                            Share
                                        </a>
                                    </div>
                                    <div className="col-9 d-flex justify-content-end">
                                        {/* <a className="btn btn-outline-primary text-white mr-2 text-uppercase">
                                            Cancel
                                        </a> */}
                                        {this.state.showEditModal ? null :
                                            <button type="submit" className="btn btn-primary text-white text-uppercase" data-toggle="modal" data-target=".save-modal" >
                                                save
                                        </button>
                                        }
                                    </div>
                                </div>
                            </form>


                        </div>
                    </section>
                </div>
                <EmailFormComponent
                    calculation={this.state.calculation}
                    showShareModal={this.state.showShareModal}
                    onClose={() => this.setState({ showShareModal: false })}
                />

                <MortgageTermOptionsComponent
                    mortgageTerm={this.state.calculation.term}
                    showModal={this.state.showOptionModal}
                    onClose={() => this.setState({ showOptionModal: false })}
                    onChange={(event: any) => this.handleCalculatorChange(event, true)}
                />
                {
                    this.state.showEditModal ?
                        <EditModal
                            name={this.state.calculation.name}
                            description={this.state.calculation.description}
                            onSave={() => this.saveCalculation()}
                            showModal={this.state.showEditModal}
                            onClose={() => this.cancelEditing()}
                            onChange={(event: any) => this.handleCalculatorChange(event, false)}
                        />
                        : null
                }
            </main>
        )
    }
}