export class UserData {
    id!: string;
    nmls!: string;
}

export default class UserDataService {
    constructor() {
        // const userEmail = localStorage.getItem('id');
        // const userNmls = localStorage.getItem('nmls');
        // this.data = {
        //     id: userEmail as string,
        //     nmls: userNmls as string,
        // }
    }

    private static instance: UserDataService;
    private data?: UserData;
    static clearData = () => localStorage.clear();

    static getInstance() {
        if (!UserDataService.instance) {
            UserDataService.instance = new UserDataService();
        }
        return UserDataService.instance;
    }

    private setDataToStorage(data: UserData) {
        if (data && data.nmls && data.id) {
            localStorage.setItem('id', data.id);
            localStorage.setItem('nmls', data.nmls);
            this.data = data;
        }
    }
    private getDataFromStorage(): UserData {
        if (!this.data) {
            const userEmail = localStorage.getItem('id');
            const userNmls = localStorage.getItem('nmls');
            this.data = {
                id: userEmail as string,
                nmls: userNmls as string,
            }
        }
        return this.data;
    }

    setUserData = (data: UserData) => this.setDataToStorage(data);

    getUserData = (): UserData => this.getDataFromStorage();



}