import * as React from 'react';
import Chart from "react-apexcharts";
import { Calculation } from '../../../types/calculation';
import "./mortgage-chart.scss"
import { IState, IProps } from '../../base/base-component';

interface IChartState extends IState {
	options: object,
	total: number,
	series: number[],
	showDefaultChart: boolean,
}

interface IChartProps extends IProps {
	calculation: Calculation;
	// calculateTotal: boolean;
	totalLoan: number;
}

const labels = ["Principle + Interest", "Property Taxes", "Home Insurance", "Mortgage Insurance", "HOA"];
const colors = ['#16c3ad', '#ffa543', '#fa5078', '#3f65fc', '#52c9fc'];

const defaultSeries = [1, 0, 0, 0, 0];
const defaultColor = ['#304170'];
const defaultLabels = ['Mortgage Calculator']


export default class MortgageChart extends React.Component<IChartProps, IChartState> {
	updating: boolean = false;

	size: number = 60;
	fontSize: number = 25;

	constructor(props: IChartProps) {
		super(props)

		this.state = {
			showDefaultChart: true,
			options: this.getOptions(),
			series: defaultSeries,
			total: 0
		}
	}

	getOptions = () => {
		return {
			colors: defaultColor,
			stroke: {
				show: true,
				width: 0,
			},
			tooltip: {
				custom: ({ series, seriesIndex, w }: any) => {
					let _tooltip = `<div style="padding: 2px 4px; font-size: 15px; z-index: 1000"> ${w.globals.labels[seriesIndex]} $${series[seriesIndex]}</div>`
					if (this.state.showDefaultChart) {
						_tooltip = `<div style="padding: 2px 4px; font-size: 15px; z-index: 1000"> ${w.globals.labels[seriesIndex]} </div>`
					}
					return _tooltip;
				}
			},
			dataLabels: {
				enabled: true,
				style: {
					fontSize: 10,
				},
				dropShadow: {
					enabled: true,
					top: 1,
					left: 1,
					blur: 2,
					opacity: 0.45
				}
			},
			fill: {
				type: 'gradient',
				gradient: {
					shade: 'dark',
					shadeIntensity: .2,
				},
			},
			plotOptions: {
				pie: {
					offsetY: 15,
					expandOnClick: true,
					dataLabels: {
						minAngleToShowLabel: 10,
					},
					donut: {

						size: this.size,
						background: 'white',
						labels: {
							show: true,
							name: {
								show: true,
								fontSize: 15,
								fontFamily: 'Montserrat, Arial, sans-serif',
								color: 'red',
								offsetY: 13
							},
							value: {
								show: true,
								fontSize: this.fontSize,
								fontFamily: 'Montserrat, Arial, sans-serif',
								color: undefined,
								offsetY: -20,
								formatter: function (val: any) {
									return val
								}
							},
							total: {
								show: true,
								showAlways: true,
								label: '(Monthly)',
								color: '#9191AB',
								formatter: (w: any) => {
									const total = w.globals.seriesTotals.reduce((a: number, b: number) => {
										return a + b
									}, 0);
									if (this.state.showDefaultChart) {
										return '$0'
									} else {
										return `$${parseFloat(`${total}`).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
									}
								}
							}
						}
					},
				}
			},
			legend: {
				show: false,
			},
			labels: defaultLabels
		}

	}

	setFontSize = (total: number): void => {
		if (total > 10000) {
			this.fontSize = 20;
			if (total > 100000) {
				this.size = 70;
			}
		} else {
			this.fontSize = 25;
			this.size = 60;
		}
	}

	setSeries(): void {
		if (this.props.calculation && !this.updating) {
			this.updating = true;
			let total: number = 0;
			const series: number[] = [
				this.props.calculation.monthlyPayment ? Math.round(this.props.calculation.monthlyPayment * 1) : 0,
				this.props.calculation.propertyTaxes ? Math.round(this.props.calculation.propertyTaxes * 1) : 0,
				this.props.calculation.homeInsurance ? Math.round(this.props.calculation.homeInsurance * 1) : 0,
				this.props.calculation.mortgageInsurance ? Math.round(this.props.calculation.mortgageInsurance * 1) : 0,
				this.props.calculation.hoa ? Math.round(this.props.calculation.hoa * 1) : 0
			];
			series.map((val: number) => { total += val })
			this.setFontSize(total)
			const _isEmpty = total == 0;
			const options: any = Object.assign({}, this.getOptions());
			options.colors = _isEmpty ? defaultColor : colors;
			options.labels = _isEmpty ? defaultLabels : labels;
			this.setState({
				total,
				options,
				showDefaultChart: _isEmpty,
				series: _isEmpty ? defaultSeries : series,
			}, () => this.updating = false)
		}
	}

	componentDidUpdate(prevProps: IChartProps): void {
		if (this.props.calculation.monthlyPayment !== prevProps.calculation.monthlyPayment ||
			this.props.calculation.propertyTaxes !== prevProps.calculation.propertyTaxes ||
			this.props.calculation.homeInsurance !== prevProps.calculation.homeInsurance ||
			this.props.calculation.mortgageInsurance !== prevProps.calculation.mortgageInsurance ||
			this.props.calculation.hoa !== prevProps.calculation.hoa)
			this.setSeries();
	}

	render() {
		return (
			<section className="card bg-dark ">
				<div className="card-body">
					<div className="row align-items-center">
						<div className="col-5 pr-0 card-chart ">
							<div className="custom-chart">
								{this.state.series ?
									<Chart
										options={this.state.options}
										series={this.state.series}
										type="donut"
										width="100%"
									/>
									: null
								}
							</div>
						</div>
						<div className="col-7 pl-3 px-2 card-body--chart text-white">
							<h6 className="mb-1 text-muted">Total Loan Amount</h6>
							<h2 className="total-price mb-3">${parseFloat(`${this.props.totalLoan}`).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</h2>
							{/* <h6 className="mb-1 text-muted">Monthly Estimated Payment</h6> */}
							{/* <h2 className="total-price mb-3">$
							{this.props.calculateTotal ?
									this.state.total
									:
									this.props.calculation.monthlyPayment
								}
							</h2> */}
							<div className="row list-of-values">
								<div className="col-9 pr-0 list-of-values__property">
									{labels.map((name: string, i: number) =>
										<p key={i}> {name}: </p>
									)}
								</div>
								<div className="col-3 pl-0 list-of-values__values">
									{this.state.series.map((value: number, i: number) =>
										<p key={i} className="text-white font-weight-bold"> $ {this.state.showDefaultChart ? 0 : value} </p>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}
}