import React from "react";
import "./mortgage-term-options.scss"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import ModalBaseComponent, { IModalBaseProps, IModalBaseState } from "../../base/modal-base-component";
import { CrossBottomSVG } from "../../../assets/icons/svg-icons";

interface IMortgageTermOptionsProps extends IModalBaseProps {
    mortgageTerm: number;
    onChange: (event: any) => void;
}

interface IMortgageTermOptionsState extends IModalBaseState {
}

export default class MortgageTermOptionsComponent extends ModalBaseComponent<IMortgageTermOptionsProps, IMortgageTermOptionsState>{
    loanThermLoocup: number[] = [15, 20, 30];

    constructor(props: IMortgageTermOptionsProps) {
        super(props);
    }

    changeValue = (e: any) => {
        this.setState({showModal: false});
        this.props.onChange(e);
    }

    render() {
        return (
            <Modal isOpen={this.state.showModal} backdrop={true} toggle={() => this.toggle()} fade={this.state.modalFade} contentClassName={"bg-dark"} centered={true} onClosed={() => this.props.onClose()}>
                <ModalHeader className="text-center" toggle={() => this.toggle()} charCode="">
                Choose a Loan Term
                    <button type="button" className="close" aria-label="Close" onClick={() => this.toggle()}>
                        <CrossBottomSVG></CrossBottomSVG>
                    </button>

                </ModalHeader>
                <ModalBody className="">
                    <div className="row">
                        <div className="col-6">
                            <div className="circle text-center">
                                <div>
                                    {this.props.mortgageTerm || 0}<br />years
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            {this.loanThermLoocup.map((value, index) =>
                                <div className="custom-control custom-radio py-2" key={index}>
                                    <input type="radio" id={"radio-term-" + index} name="term" className="custom-control-input" value={value} onChange={(e) => this.changeValue(e)} checked={this.props.mortgageTerm == value} />
                                    <label className="custom-control-label text-white" htmlFor={"radio-term-" + index}>{value} Year Fixed</label>
                                </div>)}
                        </div>
                    </div>
                </ModalBody>
                {/* <ModalFooter className="pt-0 justify-content-center">
                    <Button type="button" color="primary" className="btn text-uppercase w-100" onClick={() => this.sendEmail()}>Send Email</Button>
                </ModalFooter> */}
            </Modal>
        );
    }
}
