export enum NotificationType {
    success = 'success',
    danger = 'danger',
    info = 'info' ,
    warning = 'warning',
    default = 'default',
}

export default interface INotification {
    show(type: NotificationType, message: string, title?: string, duration?: number) :void
}