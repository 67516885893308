import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { CrossBottomSVG } from "../assets/icons/svg-icons";
import BaseComponent, { IState } from "./base/base-component";
import { Calculation } from "../types/calculation";

interface IConfirmProps {
    onDelete: (f: Function) => any;
    onConfirm: (item: Calculation) => void;
}

interface IConfirmState extends IState {
    showModal: boolean;
    deleteItem: Calculation;
}

export default class ConfirmComponent extends BaseComponent<IConfirmProps, IConfirmState>{

    constructor(props: IConfirmProps) {
        super(props);

        this.state = {
            showModal: false,
            deleteItem: new Calculation()
        }
    }

    componentDidMount() {
        this.props.onDelete(this.showConfirm)
    }

    showConfirm = (item: any): void => {
        this.setState({ showModal: true, deleteItem: item })
    }

    toggle() {
        this.setState({
            showModal: !this.state.showModal,
        });
    }

    confirm = () => {
        this.toggle();
        this.props.onConfirm(this.state.deleteItem)
    }

    render() {
        return (
            <Modal isOpen={this.state.showModal} backdrop={true} toggle={() => this.toggle()} fade={this.state.showModal} contentClassName={"bg-dark"} centered={true} onClosed={() => this.setState({ showModal: false })}>
                <ModalHeader className="text-center" toggle={() => this.toggle()} charCode="">
                Are you sure you want to delete {this.state.deleteItem && this.state.deleteItem.name ? this.state.deleteItem.name : 'this item'}&nbsp;?
                    <button type="button" className="close" aria-label="Close" onClick={() => this.toggle()}>
                        <CrossBottomSVG />
                    </button>
                </ModalHeader>
                <ModalBody >
                    <div className="col-12 d-flex justify-content-end">
                        <a className="btn btn-outline-primary text-white mr-2 text-uppercase" onClick={() => this.setState({ showModal: false })}>  Cancel </a>
                        <button type="button" onClick={() => this.confirm()} className="btn btn-primary text-white text-uppercase" data-toggle="modal" data-target=".save-modal" > OK</button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}
